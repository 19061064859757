

export class ContactRequest {
    Reasons: string;
    CompanyName: string;
    IsAgency: boolean;
    Salutation: string;
    FullName: string;
    Phone: string;
    Email: string;
    Message: string;
    Consent: boolean;
    Language: string;
}