import { Component, ChangeDetectorRef, AfterContentChecked, OnDestroy } from '@angular/core';
import { BaseComponent } from '../_components/base.component';
import { SessionService } from '../_services/session.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent extends BaseComponent {

  constructor(
    public sessionService: SessionService,
    public router: Router,
    public route: ActivatedRoute) {

    super(sessionService, router, route);

  };







  ngOnInit(): void {

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }







}
