import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { env } from "process";
import { ContactRequest } from "../_models/contactrequest";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })

export class ApiService {

    constructor(private http: HttpClient) {

    }

    ping(): Observable<HttpResponse<any>> {
        const endpoint = environment.api + 'api/ping';
        return this.http.get<HttpResponse<any>>(endpoint, { observe: 'response' });
    }

    sendContactRequest(contactRequest: ContactRequest): Observable<HttpResponse<any>> {
        const endpoint = environment.api + 'api/contacts';
        return this.http.post<HttpResponse<any>>(endpoint, contactRequest, { observe: 'response' });
    }


}