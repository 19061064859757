import { Component, ChangeDetectorRef, AfterContentChecked, OnDestroy } from '@angular/core';
import { BaseComponent } from '../_components/base.component';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { SessionService } from '../_services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PrivacyDialogComponent } from '../_components/content/privacycontent/privacydialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactRequest } from '../_models/contactrequest';
import { ApiService } from '../_services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorBoxComponent } from '../_components/errorbox/errorbox.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent extends BaseComponent implements AfterContentChecked, OnDestroy {

  subs: Subscription[] = [];
  contactForm: FormGroup;
  contactReasons: Array<any> = [
    {
      value: 'project',
      description: '__de__=Projektanfrage__en__=Inquiry for a project',
      selected: false
    },
    {
      value: 'profile',
      description: '__de__=Ausführliches Entwicklerprofil__en__=Complete developer profile',
      selected: false
    },
    {
      value: 'job',
      description: '__de__=Jobangebot__en__=Permanent position offer',
      selected: false
    },
    {
      value: 'other',
      description: '__de__=Anderes Anliegen__en__=Other',
      selected: false
    },
  ];
  showNext = false;
  showContactPerMail = false;
  showContactPerPhone = false;
  showOk = false;
  showFatalError = false;
  errorCounter: number = 0;

  constructor(
    public sessionService: SessionService,
    public router: Router,
    public route: ActivatedRoute,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private privacyDialog: MatDialog,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef) {

    super(sessionService, router, route);

    this.contactForm = formBuilder.group({
      reasonsForContact: ['', [Validators.required]],
      companyName: [''],
      isAgency: [false],
      salutation: ['', [Validators.required]],
      name: ['', [Validators.required]],
      contactPerPhone: [false, [this.anyContactFormSelected()]],
      contactPerMail: [false, [this.anyContactFormSelected()]],
      phone: [''],
      email: [''],
      message: [''],
      consent: [false, [Validators.requiredTrue]]
    });

    this.contactForm.get('email').disable();
    this.contactForm.get('phone').disable();

    this.subs.push(this.contactForm.get('contactPerMail').valueChanges.subscribe(value => {
      if (value) {
        this.contactForm.get('email').setValidators([Validators.required, Validators.email]);
        this.contactForm.get('email').enable();
        this.showContactPerMail = true;
      } else {
        this.contactForm.get('email').setValue('');
        this.contactForm.get('email').clearValidators();
        this.contactForm.get('email').disable();
        this.contactForm.get('email').setErrors(null);
        this.contactForm.get('email').markAsUntouched();
        this.showContactPerMail = false;
      }
      this.contactForm.get('email').updateValueAndValidity();
    }));

    this.subs.push(this.contactForm.get('contactPerPhone').valueChanges.subscribe(value => {
      if (value) {
        this.contactForm.get('phone').setValidators(Validators.required);
        this.contactForm.get('phone').enable();
        this.showContactPerPhone = true;

      } else {
        this.contactForm.get('phone').setValue('');
        this.contactForm.get('phone').clearValidators();
        this.contactForm.get('phone').disable();
        this.contactForm.get('phone').setErrors(null);
        this.contactForm.get('phone').markAsUntouched();
        this.showContactPerPhone = false;
      }
      this.contactForm.get('phone').updateValueAndValidity();
    }));

    this.subs.push(this.contactForm.get('reasonsForContact').valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        this.showNext = true;
      } else {
        this.showNext = false;
      }
    }));

  };

  anyContactFormSelected(): ValidatorFn {
    return (field: AbstractControl): ValidationErrors | null => {
      const form = this.contactForm;
      if (form && form.get('contactPerPhone') && form.get('contactPerMail')) {
        if (!form.get('contactPerPhone').value && !form.get('contactPerMail').value) {
          form.get('contactPerMail').setErrors(['']);
          form.get('contactPerPhone').setErrors(['']);
          return;
        }
        form.get('contactPerMail').setErrors(null);
        form.get('contactPerPhone').setErrors(null);
      }
      return;
    }
  }

  onContactReasonChanged(e: MatCheckboxChange) {
    const value = e.source.value;
    if (e.checked) {
      this.addReason(value);
    } else {
      this.removeReason(value);
    }
  }

  addReason(value: string) {
    const currentReasons = this.getCurrentReasons();
    const valuePosition = currentReasons.indexOf(value);
    if (valuePosition == -1) {
      currentReasons.push(value);
      this.setCurrentReasons(currentReasons);
    }
  }

  removeReason(value: string) {
    const currentReasons = this.getCurrentReasons();
    const valuePosition = currentReasons.indexOf(value);
    if (valuePosition > -1) {
      currentReasons.splice(valuePosition, 1);
      this.setCurrentReasons(currentReasons);
    }
  }

  getCurrentReasons(): Array<string> {
    let currentReasons: Array<string> = [];
    if (this.contactForm.get('reasonsForContact').value?.length > 0) {
      currentReasons = this.contactForm.get('reasonsForContact').value.split(',')
    }
    return currentReasons;
  }

  setCurrentReasons(reasons: Array<string>) {
    this.contactForm.get('reasonsForContact').setValue(reasons.join(','));
  }

  openPrivacyDialog() {
    this.privacyDialog.open(PrivacyDialogComponent);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }



  ngOnInit(): void {

    super.ngOnInit();

    this.subs.push(this.apiService.ping().subscribe((response) => {
      if (response.status != 200) {
        this.navigateToError('noServiceContact');
      }
    },
      (error) => {
        this.navigateToError('noServiceContact');
      }
    ));

    this.subs.push(this.route.queryParamMap.subscribe((paramsMap: any) => {
      var queryFilter = String(paramsMap.params.filter);
      if (queryFilter) {
        const filterReasons = this.contactReasons.filter(reason => queryFilter.split(',').indexOf(reason.value) > -1);
        if (filterReasons.length > 0) {
          filterReasons.forEach((reason) => {
            reason.selected = true;
            this.addReason(reason.value);
          })
        }
      }
    }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subs.forEach(sub => sub.unsubscribe());
  }



  onSubmit() {

    const contactRequest = new ContactRequest();
    contactRequest.Reasons = this.contactForm.get('reasonsForContact').value;
    contactRequest.CompanyName = this.contactForm.get('companyName').value;
    contactRequest.IsAgency = this.contactForm.get('isAgency').value;
    contactRequest.Salutation = this.contactForm.get('salutation').value;
    contactRequest.FullName = this.contactForm.get('name').value;
    contactRequest.Phone = this.contactForm.get('phone').value;
    contactRequest.Email = this.contactForm.get('email').value;
    contactRequest.Message = this.contactForm.get('message').value;
    contactRequest.Consent = this.contactForm.get('consent').value;
    contactRequest.Language = this.sessionService.getSession().language;

    this.subs.push(this.apiService.sendContactRequest(contactRequest).subscribe(
      (response) => {
        if (response.status == 201) {
          this.showOk = true;
          window.scrollTo(0, 0);
        } else {
          this.triggerError();
        }
      },
      (error) => {
        this.triggerError();
      }
    ));
  }

  triggerError() {
    this.errorCounter++;
    if (this.errorCounter < 3) {
      this.snackBar.openFromComponent(ErrorBoxComponent, {
        duration: 3000,
        data: 'contactSendFailed'
      });
    } else {
      this.showFatalError = true;
      window.scrollTo(0, 0);
    }
  }

}
