import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ProfileComponent } from './profile/profile.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ErrorComponent } from './error/error.component';
import { StackComponent } from './stack/stack.component';

const routes: Routes = [
  { path: '', component: ProfileComponent },
  { path: 'contact', component: ContactFormComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
